<template>
  <!-- <%- include('../inc/nav') -%> -->
  <main id="main">
    <!-- ======= Breadcrumbs ======= -->
    <div class="breadcrumbs">
      <div class="page-header d-flex align-items-center">
        <div class="container position-relative">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-6 text-center">
              <h2>{{ $t("contacttitle") }}</h2>
              <p>
                <span>{{ $t("contactbreif1") }}</span>
                <br /><span>{{ $t("contactbreif2") }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <nav>
        <div class="container">
          <ol>
            <li>
              <a href="/" id="nav-item">{{ $t("hometitle") }}</a>
            </li>
            <li>{{ $t("contacttitle") }}</li>
          </ol>
        </div>
      </nav>
    </div>
    <!-- End Breadcrumbs -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <div>
          <iframe
            style="border: 0; width: 100%; height: 340px"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3452.1796100389197!2d31.3016419!3d30.089042000000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDA1JzIwLjYiTiAzMcKwMTgnMDUuOSJF!5e0!3m2!1sar!2seg!4v1685217485152!5m2!1sar!2seg"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
        <!-- End Google Maps -->

        <div class="row gy-4 mt-4">
          <div class="col-12 footer-info">
            <p>
              {{ $t("contactbreif3") }}
            </p>
            <div class="social-links d-flex mt-4">
              <a href="https://twitter.com/elbanna_rashad" class="linkedin"
                ><font-awesome-icon icon="fa-brands fa-x-twitter"
              /></a>
              <a href="https://www.facebook.com/dbging" class="linkedin"
                ><font-awesome-icon icon="fa-brands fa-facebook-f"
              /></a>
              <a href="https://wa.link/ilocrn" class="linkedin"
                ><font-awesome-icon icon="fa-brands fa-whatsapp"
              /></a>
              <a
                href="https://www.linkedin.com/in/rashad-elbanna-95848a176/"
                class="linkedin"
                ><font-awesome-icon icon="fa-brands fa-linkedin-in"
              /></a>
            </div>
          </div>

          <div class="col-lg-4">
            <a
              href="https://www.google.com/maps/place/30.08904198266823, 31.30164190835163"
              class="info-item d-flex"
            >
              <font-awesome-icon icon="fa-solid fa-location-dot" />
              <div>
                <h4>{{ $t("location") }}</h4>
                <p>
                  <span>{{ $t("location1") }} </span><br />
                  <span>{{ $t("location2") }}</span>
                </p>
              </div> </a
            ><!-- End Info Item -->

            <a
              href="https://mail.google.com/mail/u/0/#inbox?compose=GTvVlcSPFdTFsVsvKgclhvSVRFjFKpKcGhPSdFGhDHWRBfMQWJvZsvPTltwzxLHCkWjmMwRXdQGjr"
              class="info-item d-flex"
            >
              <font-awesome-icon icon="fa-regular fa-envelope" />
              <div>
                <h4>{{ $t("sales") }}</h4>
                <p>sales@dbging.com</p>
              </div> </a
            ><!-- End Info Item -->

            <a href="https://wa.me/+201005043235" class="info-item d-flex">
              <font-awesome-icon icon="fa-brands fa-whatsapp" />
              <div>
                <h4>{{ $t("Whatsapp") }}</h4>
                <p>+20 100 50 432 35</p>
              </div> </a
            ><!-- End Info Item -->

            <a
              class="info-item d-flex"
              href="https://mail.google.com/mail/u/0/#inbox?compose=GTvVlcSKjRJcnkrJvhvhHwNHllzZmVlzMhpxNHLwxNLmqsBDCLrnxrlvgHgcgQrPmPRNQFgcgvDGb"
            >
              <font-awesome-icon icon="fa-regular fa-envelope" />
              <div>
                <h4>{{ $t("support") }}</h4>
                <p>Support@dbging.com</p>
              </div>
            </a>

            <a href="tel:+201005043235" class="info-item d-flex">
              <font-awesome-icon icon="fa-solid fa-mobile-screen-button" />
              <div>
                <h4>{{ $t("call") }}</h4>
                <p dir="ltr">+20 100 50 432 35</p>
              </div> </a
            ><!-- End Info Item -->
          </div>

          <div class="col-lg-8">
            <h4>{{ $t("contactreq") }}</h4>
            <form
              action="/contact"
              method="post"
              role="form"
              class="php-email-form"
            >
              <div class="row">
                <div class="form-group mt-3 col">
                  <label for="name">{{ $t("reqname") }}</label>
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                    required
                  />
                </div>
                <div class="form-group mt-3 col">
                  <label for="company">{{ $t("company") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    name="company"
                    id="company"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group mt-3 col">
                  <label for="Phone">{{ $t("Phone") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="Phone"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    maxlength="14"
                    name="phone"
                    required
                  />
                </div>
                <div class="form-group mt-3 col">
                  <label for="Whatsapp">{{ $t("Whatsapp1") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="Whatsapp"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    maxlength="14"
                    name="whatsapp"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group mt-3 col">
                  <label for="email">{{ $t("email") }}</label>
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    required
                  />
                </div>
                <div class="form-group mt-3 col">
                  <label for="subject">{{ $t("subject") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    name="subject"
                    id="subject"
                    required
                  />
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="message">{{ $t("message") }}</label>
                <textarea
                  class="form-control"
                  id="message"
                  name="message"
                  rows="5"
                  required
                ></textarea>
              </div>
              <div class="my-3">
                <div class="sent-message">{{ done }}</div>
              </div>
              <div class="text-center">
                <button class="btn btn-lg btn-primary" type="submit">
                  {{ $t("send") }}
                </button>
              </div>
            </form>
          </div>
          <!-- End Contact Form -->
        </div>
      </div>
    </section>
    <!-- End Contact Section -->
  </main>
  <!-- End main -->
</template>

<script>
export default {
  name: "ContactSection",
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.scrollToTop();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.social-links {
  place-content: space-evenly;
}
.social-links .linkedin i {
  font-size: 40px;
}
.page-header {
  background-image: url("@/assets/img/contact.jpg");
}
</style>
