<script setup>
import FaqInc from "../inc/FaqInc.vue";
</script>
<template>
  <!-- ======= Breadcrumbs ======= -->
  <div class="breadcrumbs">
    <div
      class="page-header d-flex align-items-center"
      style="background-image: url('img/terms.svg')"
    >
      <div class="container position-relative">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-6 text-center">
            <h2>{{ $t("termstitle") }}</h2>
            <p>
              <span>{{ $t("termsbreif") }}</span>
              <br /><span>{{ $t("termsbreif1") }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <nav>
      <div class="container">
        <ol>
          <li>
            <a href="/" id="nav-item">{{ $t("hometitle") }}</a>
          </li>
          <li>{{ $t("termstitle") }}</li>
        </ol>
      </div>
    </nav>
  </div>
  <!-- End Breadcrumbs -->

  <!-- ======= About Us Section ======= -->
  <section id="about" class="about">
    <div class="container" data-aos="fade-up">
      <div class="row gy-4">
        <div class="col-12 content order-last order-lg-first">
          <div class="text-center">
            <h3>{{ $t("termstitle") }}</h3>
            <p>
              <span>{{ $t("termsbreif") }}</span>
              <br /><span>{{ $t("termsbreif1") }}</span> <br /><span>{{
                $t("termsbreif2")
              }}</span>
            </p>
          </div>
          <ul>
            <li>
              <span class="fw-bold">{{ $t("termsbreif3") }} </span>
            </li>
            <li>
              <i class="bi bi-check-circle"></i
              ><span>{{ $t("termsbreif4") }}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="fw-bold">{{ $t("termsbreif5") }} </span>
            </li>
            <li>
              <i class="bi bi-check-circle"></i>
              <span>{{ $t("termsbreif6") }}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="fw-bold">{{ $t("termsbreif7") }} </span>
            </li>
            <li>
              <i class="bi bi-check-circle"></i>
              <span>{{ $t("termsbreif8") }}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="fw-bold">{{ $t("termsbreif9") }} </span>
            </li>
            <li>
              <i class="bi bi-check-circle"></i>
              <span>{{ $t("termsbreif10") }}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="fw-bold">{{ $t("termsbreif11") }} </span>
            </li>
            <li>
              <i class="bi bi-check-circle"></i>
              <span>{{ $t("termsbreif12") }}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="fw-bold">{{ $t("termsbreif13") }} </span>
            </li>
            <li>
              <i class="bi bi-check-circle"></i>
              <span>{{ $t("termsbreif14") }}</span>
            </li>
          </ul>
          <p class="mt-5">{{ $t("termsbreif15") }}</p>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Us Section -->

  <!-- ======= Frequently Asked Questions Section ======= -->
  <FaqInc />
  <!-- End Frequently Asked Questions Section -->
</template>

<script>
export default {
  name: "TermsOfService",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
ul i {
  font-size: 30px !important;
}
</style>
