<template>
  <section id="featured-services" class="featured-services">
    <div class="container">
      <div class="row gy-4">
        <div class="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up">
          <div id="featured-icon" class="icon flex-shrink-0">
            <font-awesome-icon class="i" icon="fa-solid fa-lightbulb" bounce />
          </div>
          <div>
            <h4 class="title">{{ $t("featuredtitle1") }}</h4>
            <p class="description">
              {{ $t("featuredbrif1") }}
            </p>
            <a
              id="linkservicedet"
              href="/thinking"
              class="readmore stretched-link"
              ><span>{{ $t("more") }}</span
              ><font-awesome-icon
                v-if="lang == 'AR'"
                icon="fa-solid fa-arrow-left"
                style="margin: 0 10px"
              />
              <font-awesome-icon
                v-else
                icon="fa-solid fa-arrow-right"
                style="margin: 0 10px"
              />
            </a>
          </div>
        </div>
        <!-- End Service Item -->

        <div
          class="col-lg-4 col-md-6 service-item d-flex"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div id="featured-icon-1" class="icon flex-shrink-0">
            <font-awesome-icon class="i" icon="fa-solid fa-chart-pie" spin />
          </div>
          <div>
            <h4 class="title">{{ $t("featuredtitle2") }}</h4>
            <p class="description">
              {{ $t("featuredbrif2") }}
            </p>
            <a
              id="linkservicedet"
              href="/analysis"
              class="readmore stretched-link"
              ><span>{{ $t("more") }}</span
              ><font-awesome-icon
                v-if="lang == 'AR'"
                icon="fa-solid fa-arrow-left"
                style="margin: 0 10px" />
              <font-awesome-icon
                v-else
                icon="fa-solid fa-arrow-right"
                style="margin: 0 10px"
            /></a>
          </div>
        </div>
        <!-- End Service Item -->

        <div
          class="col-lg-4 col-md-6 service-item d-flex"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div id="featured-icon-2" class="icon flex-shrink-0">
            <font-awesome-icon class="i" icon="fa-solid fa-code" beat />
          </div>
          <div>
            <h4 class="title">
              {{ $t("featuredtitle3") }}
            </h4>
            <p class="description">
              {{ $t("featuredbrif3") }}
            </p>
            <a
              id="linkservicedet"
              href="/execution"
              class="readmore stretched-link"
              ><span>{{ $t("more") }}</span
              ><font-awesome-icon
                v-if="lang == 'AR'"
                icon="fa-solid fa-arrow-left"
                style="margin: 0 10px" />
              <font-awesome-icon
                v-else
                icon="fa-solid fa-arrow-right"
                style="margin: 0 10px"
            /></a>
          </div>
        </div>
        <!-- End Service Item -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeaturedServices",
  data() {
    return {
      lang: "AR",
    };
  },
  methods: {
    checkLang() {
      if (localStorage.getItem("locale") === `AR`) {
        this.lang = "AR";
      } else {
        this.lang = "EN";
      }
    },
  },
  mounted() {
    this.checkLang();
  },
  watch: {
    "$i18n.locale": function () {
      this.checkLang();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
