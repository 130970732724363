<script setup>
import NavBar from "@/components/inc/NavBar.vue";
import PrivacyPolicy from "@/components/sections/PrivacyPolicy.vue";
import FooterBottom from "@/components/inc/FooterBottom.vue";
</script>
<!-- eslint-disable prettier/prettier -->
<template>
  <NavBar />
  <main id="main">
    <PrivacyPolicy />
  </main>
  <FooterBottom />
</template>

<script>
// @ is an alias to /src
export default {
  name: "PrivacyPolicy",
};
</script>
