/* eslint-disable no-undef */

document.addEventListener("DOMContentLoaded", () => {
  "use strict";

  /**
   * Preloader
   */
  // const preloader = document.querySelector("#preloader");
  // if (preloader) {
  //   window.addEventListener("load", () => {
  //     preloader.remove();
  //   });
  // }

  /**
   * Sticky header on scroll
   */
  const selectHeader = document.querySelector("#header");
  if (selectHeader) {
    document.addEventListener("scroll", () => {
      window.scrollY > 100
        ? selectHeader.classList.add("sticked")
        : selectHeader.classList.remove("sticked");
    });
  }

  var featured_icon_1 = document.getElementById("featured-icon-1");
  var featured_icon_2 = document.getElementById("featured-icon-2");
  var featured_icon = document.getElementById("featured-icon");
  var indexphone = document.getElementById("indexphone");
  var indexname = document.getElementById("indexname");
  var img_hero = document.getElementById("img-hero");
  var logob = document.getElementById("logob");
  var logoT = document.getElementById("logo");
  var ia_1 = document.getElementById("ia-1");
  var ia_2 = document.getElementById("ia-2");
  var ia_3 = document.getElementById("ia-3");

  if (this.$i18n.locale === `AR`) {
    logob.classList.add("logo-rtl");
    logoT.classList.add("logo-rtl");
    indexphone.setAttribute("placeholder", "رقم الهاتف");
    indexname.setAttribute("placeholder", "إسمك");
    featured_icon_1.classList.add("icon-rtl");
    featured_icon_2.classList.add("icon-rtl");
    featured_icon.classList.add("icon-rtl");
    img_hero.classList.add("animated-rtl");
    img_hero.classList.remove("animated");
    ia_1.classList.add("i-rtl");
    ia_2.classList.add("i-rtl");
    ia_3.classList.add("i-rtl");
  } else {
    logob.classList.remove("logo-rtl");
    logoT.classList.remove("logo-rtl");
    featured_icon_1.classList.remove("icon-rtl");
    featured_icon_2.classList.remove("icon-rtl");
    featured_icon.classList.remove("icon-rtl");
    img_hero.classList.remove("animated-rtl");
    img_hero.classList.add("animated");
    ia_1.classList.remove("i-rtl");
    ia_2.classList.remove("i-rtl");
    ia_3.classList.remove("i-rtl");
  }

  /**
   * Scroll top button
   */
  const scrollTop = document.querySelector(".scroll-top");
  if (scrollTop) {
    const togglescrollTop = function () {
      window.scrollY > 100
        ? scrollTop.classList.add("active")
        : scrollTop.classList.remove("active");
    };
    window.addEventListener("load", togglescrollTop);
    document.addEventListener("scroll", togglescrollTop);
    scrollTop.addEventListener(
      "click",
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    );
  }

  /**
   * Mobile nav toggle
   */
  const mobileNavShow = document.querySelector(".mobile-nav-show");
  const mobileNavHide = document.querySelector(".mobile-nav-hide");

  document.querySelectorAll(".mobile-nav-toggle").forEach((el) => {
    el.addEventListener("click", function (event) {
      event.preventDefault();
      mobileNavToogle();
    });
  });

  function mobileNavToogle() {
    document.querySelector("body").classList.toggle("mobile-nav-active");
    mobileNavShow.classList.toggle("d-none");
    mobileNavHide.classList.toggle("d-none");
  }

  /**
   * Hide mobile nav on same-page/hash links
   */
  document.querySelectorAll("#navbar a").forEach((navbarlink) => {
    if (!navbarlink.hash) return;

    let section = document.querySelector(navbarlink.hash);
    if (!section) return;

    navbarlink.addEventListener("click", () => {
      if (document.querySelector(".mobile-nav-active")) {
        mobileNavToogle();
      }
    });
  });

  /**
   * Toggle mobile nav dropdowns
   */
  const navDropdowns = document.querySelectorAll(".navbar .dropdown > a");

  navDropdowns.forEach((el) => {
    el.addEventListener("click", function (event) {
      if (document.querySelector(".mobile-nav-active")) {
        event.preventDefault();
        this.classList.toggle("active");
        this.nextElementSibling.classList.toggle("dropdown-active");

        let dropDownIndicator = this.querySelector(".dropdown-indicator");
        dropDownIndicator.classList.toggle("bi-chevron-up");
        dropDownIndicator.classList.toggle("bi-chevron-down");
      }
    });
  });

  /**
   * Initiate pURE cOUNTER
   */
  // new PureCounter();

  /**
   * Init swiper slider with 1 slide at once in desktop view
   */
  // new Swiper(".slides-1", {
  //   speed: 600,
  //   loop: true,
  //   autoplay: {
  //     delay: 5000,
  //     disableOnInteraction: false,
  //   },
  //   slidesPerView: "auto",
  //   pagination: {
  //     el: ".swiper-pagination",
  //     type: "bullets",
  //     clickable: true,
  //   },
  //   navigation: {
  //     nextEl: ".swiper-button-next",
  //     prevEl: ".swiper-button-prev",
  //   },
  // });

  /**
   * Animation on scroll function and init
   */
  // function aos_init() {
  //   AOS.init({
  //     duration: 1000,
  //     easing: "ease-in-out",
  //     once: true,
  //     mirror: false,
  //   });
  // }
  // window.addEventListener("load", () => {
  //   aos_init();
  // });

  var logo = document.getElementById("logo-main");
  var hide = document.getElementById("hide-logo");

  logo.addEventListener("mouseover", () => {
    hide.style.display = "none";
  });

  logo.addEventListener("mouseout", () => {
    hide.style.display = "block";
  });
});
