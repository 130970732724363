<script setup>
import FeaturedServices from "../inc/FeaturedServices.vue";
import FaqInc from "../inc/FaqInc.vue";
import ServiceInc from "../inc/ServiceInc.vue";
</script>

<template>
  <!-- ======= Breadcrumbs ======= -->
  <div class="breadcrumbs">
    <div class="page-header d-flex align-items-center">
      <div class="container position-relative">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-6 text-center">
            <h2>{{ $t("servicetitle1") }}</h2>
            <p>
              <span>{{ $t("servicebreif1") }}</span>
              <br /><span>{{ $t("servicebreif2") }} </span> <br /><span>{{
                $t("servicebreif3")
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <nav>
      <div class="container">
        <ol>
          <li>
            <a href="/" id="nav-item">{{ $t("hometitle") }}</a>
          </li>
          <li>{{ $t("servicetitle") }}</li>
        </ol>
      </div>
    </nav>
  </div>
  <!-- End Breadcrumbs -->

  <!-- ======= Featured Services Section ======= -->
  <FeaturedServices />
  <!-- End Featured Services Section -->

  <!-- ======= Services Section ======= -->
  <ServiceInc />
  <!-- End Services Section -->

  <!-- ======= Frequently Asked Questions Section ======= -->
  <FaqInc />
  <!-- End Frequently Asked Questions Section -->
</template>

<script>
export default {
  name: "ServiceSection",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-header {
  background-image: url("@/assets/img/service.svg");
}
</style>
