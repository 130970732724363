<script setup>
import axios from "axios";
</script>
<template>
  <section id="service" class="services pt-0">
    <div class="container" data-aos="fade-up">
      <div class="section-header">
        <span>{{ $t("servicetitle1") }}</span>
        <h2>{{ $t("servicetitle1") }}</h2>
      </div>

      <div class="row gy-4">
        <div
          class="col-lg-4 col-md-6"
          data-aos="fade-up"
          v-for="(service, index) in services"
          :key="index"
          data-aos-delay="100"
        >
          <div class="card">
            <div class="card-img">
              <img :src="service.pic" alt="" class="img-fluid" />
            </div>
            <h3>
              <a id="linkservicedet" href="contact" class="stretched-link">{{
                service.name
              }}</a>
            </h3>
            <p>
              {{ service.description }}
            </p>
          </div>
        </div>
        <!-- End Card Item -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ServiceInc",
  data() {
    return {
      services: [],
    };
  },
  methods: {
    async getServices() {
      try {
        if (localStorage.getItem("locale") === `AR`) {
          var response = await axios.get("http://localhost:3000/services");
        } else {
          // eslint-disable-next-line no-redeclare
          var response = await axios.get("http://localhost:3000/services_en");
        }
        this.services = response.data;
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    },
    goToPage(id) {
      this.$router.push({ path: "/ServiceDetails", query: { id: id } });
    },
  },
  mounted() {
    this.getServices();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
