<script>
export default {
  methods: {
    onDirLang() {
      var body = document.querySelector("body");
      if (this.$i18n.locale === "AR") {
        body.style.direction = "rtl";
      } else {
        body.style.direction = "ltr";
      }
    },
  },
  created() {
    this.onDirLang();
  },
};
</script>
<template>
  <router-view />
</template>

<style lang="scss">
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
