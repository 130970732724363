<template>
  <!-- <%- include('../inc/nav') -%>  -->
  <main id="main">
    <!-- ======= Breadcrumbs ======= -->
    <div class="breadcrumbs">
      <div class="page-header d-flex align-items-center">
        <div class="container position-relative">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-6 text-center">
              <h2>{{ $t("executiontitle1") }}</h2>
              <p>
                <span>{{ $t("executionbreif") }}</span>
                <br /><span>{{ $t("executionbreif1") }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <nav>
        <div class="container">
          <ol>
            <li>
              <a href="/" id="nav-item">{{ $t("hometitle") }}</a>
            </li>
            <li>{{ $t("executiontitle") }}</li>
          </ol>
        </div>
      </nav>
    </div>
    <!-- End Breadcrumbs -->

    <!-- ======= Service Details Section ======= -->
    <section id="service-details" class="service-details pt-4">
      <div class="container" data-aos="fade-up">
        <div class="row gy-4">
          <div class="col-lg-4">
            <div class="services-list">
              <h4>{{ $t("contactreq") }}</h4>
              <form
                action="/contact"
                method="post"
                role="form"
                class="php-email-form"
              >
                <div class="row">
                  <div class="form-group mt-3">
                    <label for="name">{{ $t("name") }}</label>
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      id="name"
                      required
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="company">{{ $t("company") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      name="company"
                      id="company"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group mt-3">
                    <label for="Phone">{{ $t("Phone") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="Phone"
                      onkeypress="return /[0-9]/i.test(event.key)"
                      maxlength="14"
                      name="phone"
                      required
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="Whatsapp">{{ $t("Whatsapp") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="Whatsapp"
                      onkeypress="return /[0-9]/i.test(event.key)"
                      maxlength="14"
                      name="whatsapp"
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group mt-3">
                    <label for="email">{{ $t("email") }}</label>
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      id="email"
                      required
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label for="subject">{{ $t("subject") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      id="subject"
                      required
                    />
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label for="message">{{ $t("message") }}</label>
                  <textarea
                    class="form-control"
                    id="message"
                    name="message"
                    rows="5"
                    required
                  ></textarea>
                </div>
                <div class="my-3">
                  <div class="sent-message">{{ done }}</div>
                </div>
                <div class="text-center">
                  <button class="btn btn-lg btn-primary" type="submit">
                    {{ $t("send") }}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="col-lg-8">
            <h3>
              {{ $t("executionbreif2") }}
            </h3>
            <img src="img/exp.svg" alt="" class="img-fluid services-img" />
            <p>
              <span>{{ $t("executionbreif") }}</span>
              <br /><span>{{ $t("executionbreif1") }}</span> <br /><span>{{
                $t("executionbreif5")
              }}</span>
            </p>
            <ul>
              <li>
                <span class="fw-bold">{{ $t("executionbreif6") }}</span>
              </li>
              <li>
                &emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif7") }}</span>
              </li>
              <li>
                &emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif8") }}</span>
              </li>
              <li>
                &emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif9") }}</span>
              </li>
              <li>
                &emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif10") }}</span>
              </li>
            </ul>
            <ul>
              <li>
                <span class="fw-bold">{{ $t("executionbreif11") }}</span>
              </li>
              <li>
                &emsp;<span>{{ $t("executionbreif12") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif13") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif14") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif15") }}</span>
              </li>
              <li>
                &emsp;<span>{{ $t("executionbreif16") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif17") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif18") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif19") }}</span>
              </li>
              <li>
                &emsp;<span>{{ $t("executionbreif20") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif21") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif22") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif23") }}</span>
              </li>
              <li>
                &emsp;<span>{{ $t("executionbreif24") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif25") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif26") }}</span>
              </li>
              <li>
                &emsp;<span>{{ $t("executionbreif27") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif28") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif29") }}</span>
              </li>
              <li>
                &emsp;&emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif30") }}</span>
              </li>
            </ul>
            <ul>
              <li>
                <span class="fw-bold">{{ $t("executionbreif31") }}</span>
              </li>
              <li>
                &emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif32") }}</span>
              </li>
              <li>
                &emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif33") }}</span>
              </li>
              <li>
                &emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif34") }}</span>
              </li>
            </ul>
            <ul>
              <li>
                <span class="fw-bold">{{ $t("executionbreif35") }}</span>
              </li>
              <li>
                &emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif36") }}</span>
              </li>
              <li>
                &emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif37") }}</span>
              </li>
              <li>
                &emsp;<i class="bi bi-check-circle"></i>
                <span>{{ $t("executionbreif38") }}</span>
              </li>
            </ul>
            <p>
              <span>{{ $t("executionbreif39") }}</span>
              <br /><span>{{ $t("executionbreif40") }}</span> <br /><span>{{
                $t("executionbreif41")
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- End Service Details Section -->
  </main>
</template>

<script>
export default {
  name: "ExecutionSection",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-header {
  background-image: url("@/assets/img/ex.svg");
}
</style>
