import { createApp } from "vue";
import App from "./App.vue";

import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";

import router from "./router";
import store from "./store";

import AR from "./locale/ar.json";
import EN from "./locale/en.json";

import "@/assets/main.css";
import "@/assets/main.js";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "normalize.css";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faArrowRight,
  faBars,
  faChartPie,
  faCode,
  faCoffee,
  faLightbulb,
  faLocationDot,
  faMinus,
  faMobileScreenButton,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { faBookOpenReader } from "@fortawesome/free-solid-svg-icons";
import { faKitchenSet } from "@fortawesome/free-solid-svg-icons";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faLinkedinIn,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

library.add(
  faCoffee,
  faBookOpenReader,
  faKitchenSet,
  faBullseye,
  faArrowRight,
  faArrowLeft,
  faLightbulb,
  faChartPie,
  faCode,
  faMinus,
  faFacebookF,
  faXTwitter,
  faWhatsapp,
  faLinkedinIn,
  faLocationDot,
  faEnvelope,
  faMobileScreenButton,
  faBars,
  faX
);

const locale = localStorage.getItem("locale") || "AR";
const i18n = createI18n({
  locale: locale,
  messages: {
    AR: AR,
    EN: EN,
  },
});

const app = createApp(App);

app.use(i18n);

app.use(createPinia());
app.component("font-awesome-icon", FontAwesomeIcon);

app.use(store);
app.use(router);
app.mount("#app");
