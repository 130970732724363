<script setup>
import FaqInc from "../inc/FaqInc.vue";
</script>
<template>
  <!-- ======= Breadcrumbs ======= -->
  <div class="breadcrumbs">
    <div
      class="page-header d-flex align-items-center"
      style="background-image: url('img/privcy.svg')"
    >
      <div class="container position-relative">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-6 text-center">
            <h2>{{ $t("privacytitle") }}</h2>
            <p>
              <span>{{ $t("privacybreif") }}</span>
              <br /><span>{{ $t("privacybreif1") }}</span> <br /><span>{{
                $t("privacybreif2")
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <nav>
      <div class="container">
        <ol>
          <li>
            <a id="nav-item" href="/">{{ $t("hometitle") }}</a>
          </li>
          <li>{{ $t("privacytitle") }}</li>
        </ol>
      </div>
    </nav>
  </div>
  <!-- End Breadcrumbs -->

  <!-- ======= About Us Section ======= -->
  <section id="about" class="about">
    <div class="container" data-aos="fade-up">
      <div class="row gy-4">
        <div class="col-12 content order-last order-lg-first">
          <div class="text-center">
            <h3>{{ $t("privacytitle") }}</h3>
            <p>
              <span>{{ $t("privacybreif") }}</span>
              <br /><span>{{ $t("privacybreif1") }}</span> <br /><span>{{
                $t("privacybreif2")
              }}</span>
            </p>
          </div>
          <ul>
            <li>
              <span class="fw-bold">{{ $t("privacybreif3") }}</span>
            </li>
            <li>
              <i class="bi bi-check-circle"></i
              ><span>{{ $t("privacybreif4") }}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="fw-bold">{{ $t("privacybreif5") }} </span>
            </li>
            <li>
              <i class="bi bi-check-circle"></i>
              <span>{{ $t("privacybreif6") }}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="fw-bold">{{ $t("privacybreif7") }} </span>
            </li>
            <li>
              <i class="bi bi-check-circle"></i>
              <span>{{ $t("privacybreif8") }}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="fw-bold">{{ $t("privacybreif9") }} </span>
            </li>
            <li>
              <i class="bi bi-check-circle"></i>
              <span>{{ $t("privacybreif10") }}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="fw-bold">{{ $t("privacybreif11") }} </span>
            </li>
            <li>
              <i class="bi bi-check-circle"></i>
              <span>{{ $t("privacybreif12") }}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="fw-bold">{{ $t("privacybreif13") }} </span>
            </li>
            <li>
              <i class="bi bi-check-circle"></i>
              <span>{{ $t("privacybreif14") }}</span>
            </li>
          </ul>
          <p class="mt-5">
            <span>{{ $t("privacybreif15") }}</span
            ><a href="/contact" id="list-item" class="text-main"> Debug </a>.
          </p>
          <p>{{ $t("privacybreif16") }}</p>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Us Section -->

  <!-- ======= Frequently Asked Questions Section ======= -->
  <FaqInc />
  <!-- End Frequently Asked Questions Section -->
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
ul i {
  font-size: 30px !important;
}
</style>
