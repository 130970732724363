<template>
  <!-- <%- include('../inc/nav') -%> -->
  <main id="main">
    <!-- ======= Portfolio Section ======= -->
    <section id="portfolio" class="portfolio section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>أعمالنا</h2>
          <p>
            بعض اعمالنا في تطبيقات الهواتف وبناء المواقع والتطبيقات المكتبية
          </p>
        </div>

        <!-- <div class="row">
          <div class="col-lg-12 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
            <ul id="portfolio-flters">
              <li data-filter="*" class="filter-active">All</li>
              <li data-filter=".filter-app">Mobile</li>
              <li data-filter=".filter-web">Web</li>
            </ul>
          </div>
        </div>  -->

        <div
          id="form"
          class="row portfo-space"
          style="
            position: relative;
            height: 75vh !important;
            overflow-y: scroll;
          "
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <!-- <% if(fetch.length > 0){ var i=1; fetch.forEach(function(el){ %> -->
          <div class="col-lg-4 col-md-6 portfolio-item <%=el.filter%>">
            <div class="portfolio-wrap">
              <img src="<%=el.imge%>" alt="" class="img-fluid" />
              <div class="portfolio-info">
                <h4>
                  <!-- <%=el.name%> -->
                </h4>
                <p style="padding: 0 20px">
                  <!-- <%=el.subject%> -->
                </p>
                <div class="portfolio-links">
                  <!-- <a href="/portfolio-details" class="portfolio-details-lightbox"><i class='bx bx-link'></i></a> -->
                </div>
              </div>
            </div>
          </div>
          <!-- <% i++; }) } %> -->
        </div>

        <div id="picScreen" class="screenPic"></div>
      </div>
    </section>
    <!-- End Portfolio Section -->
  </main>
  <!-- End #main -->
</template>

<script>
export default {
  name: "PortfolioSection",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
