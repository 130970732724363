<template>
  <!-- <%- include('../inc/nav') -%> -->
  <main id="main">
    <!-- ======= Breadcrumbs ======= -->
    <div class="breadcrumbs">
      <div
        class="page-header d-flex align-items-center"
        style="background-image: url('@/assets/img/quote.svg')"
      >
        <div class="container position-relative">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-6 text-center">
              <h2>{{ $t("getquote") }}</h2>
              <p>{{ $t("getquotebreif") }}</p>
            </div>
          </div>
        </div>
      </div>
      <nav>
        <div class="container">
          <ol>
            <li>
              <a href="/" id="nav-item">{{ $t("hometitle") }}</a>
            </li>
            <li>{{ $t("getquote") }}</li>
          </ol>
        </div>
      </nav>
    </div>
    <!-- End Breadcrumbs -->

    <!-- ======= Get a Price Quote Section ======= -->
    <section id="get-a-quote" class="get-a-quote">
      <div class="container" data-aos="fade-up">
        <div class="row g-0">
          <div class="col-lg-7 m-auto">
            <form
              action="get-a-price"
              method="post"
              class="php-email-form"
              enctype="multipart/form-data"
            >
              <h3>{{ $t("getquote") }}</h3>
              <p>{{ $t("getquotebreif1") }}</p>
              <div class="row gy-4">
                <div class="col-6">
                  <label for="company">{{ $t("companyname") }}</label>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    class="form-control"
                    required
                  />
                </div>

                <div class="col-6">
                  <label for="address2">{{ $t("companyphone") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="address2"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    maxlength="14"
                    name="phone"
                  />
                </div>

                <div class="col-12">
                  <label for="files">{{ $t("projectfile") }}</label>
                  <input
                    type="file"
                    name="files"
                    id="files"
                    class="form-control"
                    required
                  />
                </div>

                <div class="col-md-12">
                  <label for="email">{{ $t("companyemail") }}</label>
                  <input
                    type="email"
                    id="email"
                    class="form-control"
                    name="email"
                    required
                  />
                </div>

                <div class="col-lg-12">
                  <h4>{{ $t("personaldet") }}</h4>
                </div>

                <div class="col-md-12">
                  <label for="email">{{ $t("reqname") }}</label>
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    required
                  />
                </div>

                <div class="col-12">
                  <label for="email">{{ $t("Whatsapp1") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="address2"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    maxlength="14"
                    name="whatsapp"
                  />
                </div>

                <div class="col-md-12">
                  <label for="">{{ $t("writehere") }}</label>
                  <textarea
                    class="form-control"
                    name="message"
                    rows="6"
                    required
                  ></textarea>
                </div>

                <div class="col-md-12 text-center">
                  <div class="sent-message">{{ done }}</div>

                  <button type="submit">
                    {{ $t("getquote") }}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!-- End Quote Form -->
        </div>
      </div>
    </section>
    <!-- End Get a Price Quote Section -->
  </main>
  <!-- End main -->
</template>

<script>
export default {
  name: "GetAQuote",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
