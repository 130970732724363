<script setup>
import FeaturedServices from "../inc/FeaturedServices.vue";
import FaqInc from "../inc/FaqInc.vue";
</script>
<template>
  <!-- ======= Featured Services Section ======= -->
  <FeaturedServices />
  <!-- End Featured Services Section -->

  <!-- ======= About Us Section ======= -->
  <section id="about" class="about pt-0">
    <div class="container" data-aos="fade-up">
      <div class="row gy-4">
        <div
          class="col-lg-6 position-relative align-self-start order-lg-last order-first"
        >
          <img src="@/assets/img/about.jpg" class="img-fluid" alt="" />
          <a href="https://youtu.be/WASKOtH5rV4" class="glightbox play-btn"></a>
        </div>
        <div class="col-lg-6 content order-last order-lg-first pt-3">
          <h3 class="py-3">{{ $t("abouttitle2") }}</h3>
          <p class="py-3">
            <span class="text-main fw-bold">{{ $t("delete") }}</span>
            <span>{{ $t("inabout1") }}</span>
            <br /><span>{{ $t("inabout2") }}</span> <br />
            <span>{{ $t("inabout3") }}</span>
          </p>
          <ul>
            <li data-aos="fade-up" data-aos-delay="100">
              <font-awesome-icon
                class="iconsFont"
                id="ia-1"
                icon="fa-solid fa-book-open-reader"
                fade
              />
              <div>
                <h5>{{ $t("headabout1") }}</h5>
                <p>{{ $t("inabout4") }}</p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              <font-awesome-icon
                class="iconsFont"
                id="ia-2"
                icon="fa-solid fa-kitchen-set"
                fade
              />
              <div>
                <h5>{{ $t("headabout2") }}</h5>
                <p>{{ $t("inabout5") }}</p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <i class="fa-solid fa-bullseye fa-fade"></i>
              <font-awesome-icon
                class="iconsFont"
                id="ia-3"
                icon="fa-solid fa-bullseye"
                fade
              />
              <div>
                <h5>{{ $t("headabout3") }}</h5>
                <p>{{ $t("inabout6") }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Us Section -->

  <!-- ======= Call To Action Section ======= -->
  <section id="call-to-action" class="call-to-action">
    <div class="container" data-aos="zoom-out">
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center">
          <h3>{{ $t("callaction") }}</h3>
          <p>
            <span>{{ $t("callaction1") }}</span>
            <br /><span>{{ $t("callaction2") }}</span> <br /><span>{{
              $t("callaction3")
            }}</span>
            <br /><span>{{ $t("callaction4") }}</span> <br /><span>{{
              $t("callaction5")
            }}</span>
            <br /><span>{{ $t("callaction6") }}</span>
          </p>
          <a class="cta-btn" href="contact">{{ $t("callaction") }}</a>
        </div>
      </div>
    </div>
  </section>

  <!-- End Call To Action Section -->
  <FaqInc />
</template>

<script>
export default {
  name: "HomeSection",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
