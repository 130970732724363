<script setup>
import NavBar from "@/components/inc/NavBar.vue";
import HeroComp from "@/components/inc/HeroComp.vue";
import HomeSection from "@/components/sections/HomeSection.vue";
import FooterBottom from "@/components/inc/FooterBottom.vue";
</script>
<!-- eslint-disable prettier/prettier -->
<template>
  <NavBar />
  <HeroComp />
  <main id="main">
    <HomeSection />
  </main>
  <FooterBottom />
</template>

<script>
// @ is an alias to /src
export default {
  name: "HomeView",
  methods: {
    log() {
      console.log(this.$i18n.locale);
    },
  },
  created() {
    this.log();
  },
};
</script>
