<template>
  <!-- ======= Footer ======= -->
  <footer id="footer" class="footer">
    <div class="container">
      <div class="row gy-4">
        <div class="col-lg-6 col-md-12 footer-info">
          <div
            class="container-fluid container-xl d-flex align-items-center justify-content-between"
          >
            <a id="logob" class="logo-rtl logo d-flex align-items-center">
              <img
                id="logo-main"
                src="@/assets/img/logo/debugfram.svg"
                alt=""
              />
              <h3 id="hide-logo">EBUG</h3>
            </a>
          </div>
          <p>{{ $t("footerbrif") }}</p>
          <div class="social-links d-flex mt-4">
            <a href="https://twitter.com/elbanna_rashad" class="twitter"
              ><font-awesome-icon icon="fa-brands fa-x-twitter"
            /></a>
            <a href="https://www.facebook.com/debugsoftware" class="facebook"
              ><font-awesome-icon icon="fa-brands fa-facebook-f"
            /></a>
            <a href="https://wa.me/+201115392737" class="instagram"
              ><font-awesome-icon icon="fa-brands fa-whatsapp"
            /></a>
            <a
              href="https://www.linkedin.com/company/debug-software/"
              class="instagram"
              ><font-awesome-icon icon="fa-brands fa-linkedin-in"
            /></a>
          </div>
        </div>

        <div class="col-lg-3 col-6 footer-links">
          <h4>{{ $t("uslink") }}</h4>
          <ul>
            <li>
              <a id="list-item" href="/">{{ $t("hometitle") }}</a>
            </li>
            <li>
              <a id="list-item" href="/about">{{ $t("abouttitle") }}</a>
            </li>
            <li>
              <a id="list-item" href="/services">{{ $t("servicetitle") }}</a>
            </li>
            <li>
              <a id="list-item" href="/terms">{{ $t("termstitle") }}</a>
            </li>
            <li>
              <a id="list-item" href="/privacy">{{ $t("privacytitle") }}</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-12 footer-contact">
          <h4>{{ $t("contacttitle") }}</h4>
          <p>
            <span>{{ $t("addresscompany") }} </span><br />
            <span>{{ $t("addresscompany1") }}</span
            ><br />
            <span>{{ $t("addresscompany2") }}</span> <br /><br />
            <strong>{{ $t("phonestrong") }} </strong
            ><span dir="ltr"> +20 111 539 2737 </span><br />
            <strong>{{ $t("emailstrong") }}</strong>
            info@dbging.com<br />
            <!-- </p> -->
          </p>
        </div>
      </div>
    </div>

    <div class="container mt-4">
      <div class="copyright">
        &copy; <span> {{ $t("copyright") }}</span>
        <strong>{{ $t("delete") }}</strong
        ><span> {{ $t("rights") }}</span>
      </div>
      <div class="credits">
        <span>{{ $t("devolpedby") }}</span>
        <a id="linkservicedet" href="/">{{ $t("debug") }}</a>
      </div>
    </div>
  </footer>
  <!-- End Footer -->

  <a
    href="#"
    class="scroll-top d-flex align-items-center justify-content-center"
    ><i class="bx bx-up-arrow-alt"></i
  ></a>
</template>

<script>
export default {
  name: "FooterBottom",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
