<script setup>
import { RouterLink } from "vue-router";
</script>
<template>
  <!-- ======= Breadcrumbs ======= -->
  <div class="breadcrumbs">
    <div class="page-header d-flex align-items-center">
      <div class="container position-relative">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-6 text-center">
            <h2>{{ $t("abouttitle") }}</h2>
            <p>
              <span class="fw-bold">{{ $t("delete") }}</span
              ><span>{{ $t("aboutbrif") }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <nav>
      <div class="container">
        <ol>
          <li>
            <RouterLink to="/" id="nav-item">{{ $t("hometitle") }}</RouterLink>
          </li>
          <li>{{ $t("abouttitle") }}</li>
        </ol>
      </div>
    </nav>
  </div>
  <!-- End Breadcrumbs -->

  <!-- ======= About Us Section ======= -->
  <section id="about" class="about">
    <div class="container" data-aos="fade-up">
      <div class="row gy-4">
        <div class="col-lg-6">
          <div
            class="position-relative align-self-start order-lg-last order-first"
          >
            <img src="@/assets/img/about.jpg" class="img-fluid" alt="" />
            <RouterLink
              to="https://www.youtube.com/watch?v=LXb3EKWsInQ"
              class="glightbox play-btn"
            ></RouterLink>
          </div>
          <div class="content order-last order-lg-first">
            <ul>
              <li data-aos="fade-up" data-aos-delay="300">
                <font-awesome-icon icon="fa-solid fa-minus" />
                <div>
                  <p>
                    <span>{{ $t("aboutbrif1") }}</span>
                    <span class="text-main fw-bold">{{ $t("delete") }}</span
                    ><span>{{ $t("aboutbrif2") }}</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 content order-last order-lg-first">
          <h3>{{ $t("abouttitle2") }}</h3>
          <p>
            <span>{{ $t("aboutbrif3") }}</span>
            <br /><span>{{ $t("aboutbrif4") }}</span>
          </p>
          <ul>
            <li data-aos="fade-up" data-aos-delay="100">
              <font-awesome-icon icon="fa-solid fa-minus" />
              <div>
                <p>
                  <span class="text-main fw-bold">{{ $t("delete") }}</span
                  ><span>{{ $t("aboutbrif5") }}</span>
                </p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              <font-awesome-icon icon="fa-solid fa-minus" />
              <div>
                <p>{{ $t("aboutbrif6") }}</p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <font-awesome-icon icon="fa-solid fa-minus" />
              <div>
                <p>
                  <span> {{ $t("aboutbrif7") }}</span>
                  <span class="text-main fw-bold">{{ $t("delete") }}</span
                  ><span>{{ $t("aboutbrif8") }}</span>
                </p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <font-awesome-icon icon="fa-solid fa-minus" />
              <div>
                <p>{{ $t("aboutbrif9") }}</p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <font-awesome-icon icon="fa-solid fa-minus" />
              <div>
                <p>
                  <span class="text-main fw-bold">{{ $t("delete") }}</span
                  ><span>{{ $t("aboutbrif10") }}</span>
                </p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <font-awesome-icon icon="fa-solid fa-minus" />
              <div>
                <p>{{ $t("aboutbrif11") }}</p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <font-awesome-icon icon="fa-solid fa-minus" />
              <div>
                <p>
                  <span>{{ $t("aboutbrif12") }} </span>
                </p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <font-awesome-icon icon="fa-solid fa-minus" />
              <div>
                <p>{{ $t("aboutbrif13") }}</p>
              </div>
            </li>
          </ul>
        </div>
        <p class="text-center text-black">{{ $t("aboutbrif14") }}</p>
      </div>
      <hr />
    </div>
  </section>
  <!-- End About Us Section -->
</template>

<script>
export default {
  name: "AboutSection",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-header {
  background-image: url("@/assets/img/about-bg.jpg");
}
</style>
