<script setup>
import axios from "axios";
</script>
<template>
  <section id="faq" class="faq">
    <div class="container" data-aos="fade-up">
      <div class="section-header">
        <span>{{ $t("faqtitle") }}</span>
        <h2>{{ $t("faqtitle") }}</h2>
      </div>

      <div
        class="row justify-content-center"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div class="col-lg-10">
          <div class="accordion accordion-flush" id="faqlist">
            <div
              class="accordion-item"
              v-for="(question, index) in questions"
              :key="index"
            >
              <h3 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#faq-content-' + question.id"
                >
                  <i class="bi bi-question-circle question-icon"></i>
                  {{ question.question }}
                </button>
              </h3>
              <div
                :id="'faq-content-' + question.id"
                class="accordion-collapse collapse"
                data-bs-parent="#faqlist"
              >
                <div class="accordion-body">
                  {{ question.answer }}
                </div>
              </div>
            </div>
            <!-- # Faq item-->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FaqInc",
  data() {
    return {
      questions: [],
    };
  },
  methods: {
    async getQuestions() {
      try {
        if (localStorage.getItem("locale") === `AR`) {
          var response = await axios.get("http://localhost:3000/questions");
        } else {
          // eslint-disable-next-line no-redeclare
          var response = await axios.get("http://localhost:3000/questions_en");
        }
        this.questions = response.data;
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    },
  },
  mounted() {
    this.getQuestions();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
