<script setup>
// import { a } from "vue-router";
</script>

<template>
  <header id="header" class="header d-flex align-items-center fixed-top">
    <div
      class="container-fluid container-xl d-flex align-items-center justify-content-between"
    >
      <a href="/" id="logo_nav" class="logo-rtl logo d-flex align-items-center">
        <img id="logo-main" src="@/assets/img/logo/debugfram.svg" alt="" />
        <h3 id="hide-logo">ebug</h3>
      </a>

      <i
        v-if="showMenu == true"
        @click="toggleMenu()"
        class="mobile-nav-toggle mobile-nav-show"
      >
        <font-awesome-icon icon="fa-solid fa-bars" />
      </i>
      <i
        v-if="showMenu == false"
        @click="toggleMenu()"
        class="mobile-nav-toggle mobile-nav-hide"
      >
        <font-awesome-icon icon="fa-solid fa-x" />
      </i>
      <nav id="navbar" class="navbar">
        <ul>
          <li>
            <a id="nav-item" href="/" class="active">{{ $t("hometitle") }}</a>
          </li>
          <li>
            <a id="nav-item" href="/about">{{ $t("abouttitle") }}</a>
          </li>
          <li>
            <a id="nav-item" href="/services">{{ $t("servicetitle") }}</a>
          </li>
          <!-- <li><a id="nav-item" href="/pricing">{{ $t("pricingtitle") }}</a></li> -->
          <li>
            <a id="nav-item" href="/contact">{{ $t("contacttitle") }}</a>
          </li>
          <li class="dropdown">
            <a href="#"
              ><span style="margin: 0 10px">{{ $t("langtitle") }}</span>
              <i class="bx bxs-chevron-down bx-sm dropdown-indicator"></i
            ></a>
            <ul id="selectlang">
              <li>
                <a id="en" @click="changeLang('EN')">English</a>
              </li>
              <li>
                <a id="ar" @click="changeLang('AR')">عربي</a>
              </li>
            </ul>
          </li>
          <li>
            <a class="get-a-quote" href="/getAQuote">{{ $t("getprice") }}</a>
          </li>
        </ul>
      </nav>
      <!-- .navbar -->
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      showMenu: true,
    };
  },
  name: "NavBar",
  methods: {
    changeLang(lang) {
      var body = document.querySelector("body");
      if (lang == `AR`) {
        this.$i18n.locale = "AR";
        body.style.direction = "rtl";
        localStorage.setItem("locale", "AR");
      } else {
        this.$i18n.locale = "EN";
        body.style.direction = "ltr";
        localStorage.setItem("locale", "EN");
      }
      window.location.reload();
    },
    toggleMenu() {
      var navbar = document.getElementById("navbar");
      this.showMenu = !this.showMenu;
      if (this.showMenu == true) {
        navbar.style.right = "-100%";
      } else {
        navbar.style.right = "0";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
