<script setup>
import NavBar from "@/components/inc/NavBar.vue";
import AnalysisView from "@/components/sections/AnalysisSection.vue";
import FooterBottom from "@/components/inc/FooterBottom.vue";
</script>
<!-- eslint-disable prettier/prettier -->
<template>
  <NavBar />
  <main id="main">
    <AnalysisView />
  </main>
  <FooterBottom />
</template>

<script>
// @ is an alias to /src
export default {
  name: "AnalysisView",
};
</script>
