import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import AnalysisView from "../views/AnalysisView.vue";
import ContactView from "../views/ContactView.vue";
import ExecutionView from "../views/ExecutionView.vue";
import GetAQuoteView from "../views/GetAQuoteView.vue";
import PortfolioView from "../views/PortfolioView.vue";
import PrivacyView from "../views/PrivacyView.vue";
import PricingView from "../views/PricingView.vue";
import ServiceDetails from "../views/ServiceDetails.vue";
import ServicesView from "../views/ServicesView.vue";
import TermsOfServiceView from "../views/TermsOfService.vue";
import ThinkingView from "../views/ThinkingView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/analysis",
    name: "AnalysisView",
    component: AnalysisView,
  },
  {
    path: "/contact",
    name: "ContactView",
    component: ContactView,
  },
  {
    path: "/execution",
    name: "ExecutionView",
    component: ExecutionView,
  },
  {
    path: "/getAQuote",
    name: "GetAQuote",
    component: GetAQuoteView,
  },
  {
    path: "/portfolio",
    name: "PortfolioView",
    component: PortfolioView,
  },
  {
    path: "/pricing",
    name: "PricingView",
    component: PricingView,
  },
  {
    path: "/privacy",
    name: "PrivacyView",
    component: PrivacyView,
  },
  {
    path: "/serviceDetails",
    name: "ServiceDetails",
    component: ServiceDetails,
  },
  {
    path: "/services",
    name: "ServicesView",
    component: ServicesView,
  },
  {
    path: "/terms",
    name: "TermsOfService",
    component: TermsOfServiceView,
  },
  {
    path: "/thinking",
    name: "ThinkingView",
    component: ThinkingView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
