<script setup>
import axios from "axios";
</script>
<template>
  <!-- ======= Breadcrumbs ======= -->
  <div class="breadcrumbs">
    <div class="page-header d-flex align-items-center">
      <div class="container position-relative">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-6 text-center">
            <h2>
              {{ services[0].name }}
            </h2>
            <p>
              {{ services[0].brif }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <nav>
      <div class="container">
        <ol>
          <li>
            <a href="/" id="nav-item">{{ $t("hometitle") }}</a>
          </li>
          <li>
            <!-- {{ services.name }} -->
            <span>{{ $t("servicetitle") }}</span>
          </li>
        </ol>
      </div>
    </nav>
  </div>
  <!-- End Breadcrumbs -->

  <!-- ======= Service Details Section ======= -->
  <section id="service-details" class="service-details">
    <div class="container" data-aos="fade-up">
      <div class="row gy-4">
        <div class="col-lg-4">
          <div class="services-list">
            <h4>{{ $t("contactreq") }}</h4>
            <form
              action="/contact"
              method="post"
              role="form"
              class="php-email-form"
            >
              <div class="row">
                <div class="form-group mt-3">
                  <label for="name">{{ $t("name") }}</label>
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                    required
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="company">{{ $t("company") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    name="company"
                    id="company"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group mt-3">
                  <label for="Phone">{{ $t("Phone") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="Phone"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    maxlength="14"
                    name="phone"
                    required
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="Whatsapp">{{ $t("Whatsapp") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="Whatsapp"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    maxlength="14"
                    name="whatsapp"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group mt-3">
                  <label for="email">{{ $t("email") }}</label>
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    required
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="subject">{{ $t("subject") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    name="subject"
                    id="subject"
                    required
                  />
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="message">{{ $t("message") }}</label>
                <textarea
                  class="form-control"
                  id="message"
                  name="message"
                  rows="5"
                  required
                ></textarea>
              </div>
              <div class="my-3">
                <div class="sent-message">{{ done }}</div>
              </div>
              <div class="text-center">
                <button class="btn btn-lg btn-primary" type="submit">
                  {{ $t("send") }}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="col-lg-8">
          <h3>
            <!-- <%=service[0].sername%> -->
          </h3>
          <img
            src="http://localhost:4400/img/service/service/serpic"
            alt=""
            class="img-fluid services-img"
          />
          <h4>
            <!-- <%=service[0].address%> -->
          </h4>
          <!-- <ul>
                <li><i class="bi bi-check-circle"></i> <span>Aut eum totam accusantium voluptatem.</span></li>
                <li><i class="bi bi-check-circle"></i> <span>Assumenda et porro nisi nihil nesciunt voluptatibus.</span></li>
                <li><i class="bi bi-check-circle"></i> <span>Ullamco laboris nisi ut aliquip ex ea</span></li>
              </ul> -->
          <!-- <% var i = 1;
                service.forEach(function(el){ %> -->
          <p class="fw-bold">
            <!-- <%=el.head%> -->
          </p>
          <p>
            &emsp;
            <!-- <%=el.des%> -->
          </p>
          <!-- <% i++; }) %> -->
        </div>
      </div>
    </div>
  </section>
  <!-- End Service Details Section -->
  <!-- <% } %> -->
</template>

<script>
export default {
  name: "ServiceDetails",
  data() {
    return {
      serviceId: this.$route.query.id,
      servicesDet: [],
      services: [],
    };
  },
  methods: {
    async getServices() {
      try {
        if (localStorage.getItem("locale") === `AR`) {
          var response = await axios.get("http://localhost:3000/services_det");
          var res = axios.get("http://localhost:3000/services");
        } else {
          // eslint-disable-next-line no-redeclare
          var response = await axios.get(
            "http://localhost:3000/services_det_en"
          );
          // eslint-disable-next-line no-redeclare
          var res = axios.get("http://localhost:3000/services_en");
        }
        this.servicesDet = response.data;
        this.services = res.data.filter(
          (service) => service.id == this.serviceId
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    },
  },
  mounted() {
    this.serviceId = this.$route.query.id;
    this.getServices();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
