<template>
  <section id="hero" class="hero d-flex align-items-center">
    <div class="container">
      <div class="row gy-4 d-flex justify-content-between">
        <div
          class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center mt-4 mb-3"
        >
          <h2 data-aos="fade-up">
            <span class="fw-bold">{{ $t("delete") }}</span
            ><span> {{ $t("title") }}</span>
          </h2>
          <p data-aos="fade-up" data-aos-delay="100">
            <span class="fw-bold">{{ $t("delete") }}</span>
            <span>{{ $t("brif") }}</span>
            <br />
            <span>{{ $t("brif2") }}</span>
          </p>

          <h5 data-aos="fade-up" class="text-white fw-bold">
            {{ $t("requestquote") }}
          </h5>
          <h6 data-aos="fade-up" class="text-white">
            <!-- <%=sendreq%> -->
          </h6>

          <form
            action="vrivcation"
            method="POST"
            class="form-search d-flex align-items-stretch mb-3 mt-4"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <input
              type="text"
              id="indexname"
              class="form-control text-center"
              name="contry"
              placeholder="Your contry"
              required
            />
            <input
              type="text"
              class="form-control text-center"
              id="numberVri"
              onkeypress="return /[0-9]/i.test(event.key)"
              maxlength="14"
              placeholder="Your Phone"
              name="numberVri"
              required
            />
            <button type="submit" class="btn btn-primary">
              {{ $t("btncontact") }}
            </button>
          </form>

          <!-- <div class="row gy-4" data-aos="fade-up" data-aos-delay="400">

            <div class="col-lg-3 col-6">
              <div class="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" class="purecounter"></span>
                <p>Clients</p>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <div class="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" class="purecounter"></span>
                <p>Projects</p>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <div class="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="1453" data-purecounter-duration="1" class="purecounter"></span>
                <p>Support</p>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <div class="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" class="purecounter"></span>
                <p>Workers</p>
              </div>
            </div>

          </div> -->
        </div>

        <div class="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out">
          <img
            id="img-hero"
            src="@/assets/img/bg.png"
            class="img-fluid mb-3 mb-lg-0 animated"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroComp",
  methods: {
    checkLang() {
      var indexphone = document.getElementById("numberVri");
      var indexname = document.getElementById("indexname");
      if (localStorage.getItem("locale") === `AR`) {
        indexphone.setAttribute("placeholder", "رقم الهاتف");
        indexname.setAttribute("placeholder", "إسمك");
      } else {
        indexphone.setAttribute("placeholder", "Your Phone");
        indexname.setAttribute("placeholder", "Your Name");
      }
    },
  },
  mounted() {
    this.checkLang();
  },
  watch: {
    "$i18n.locale": function () {
      this.checkLang();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
